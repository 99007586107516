import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import MultiRangeSlider from '../components/MultiRangeSlider/MultiRangeSlider'

const Filters = ({ filtersSchema, onFilterChange, productType }) => {
  const initialMaxPrice = productType === 'panel' ? 999 : 49999;
  const [filters, setFilters] = useState({});
  const [tempFilters, setTempFilters] = useState({});
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice);
  const [minPower, setMinPower] = useState('');
  const [maxPower, setMaxPower] = useState('');
  const [visibleManufacturers, setVisibleManufacturers] = useState(10);

  const [visibleFilters, setVisibleFilters] = useState({
    price: true,
    power: true,
    ...filtersSchema.reduce((acc, filter) => {
      acc[filter.name] = false;
      return acc;
    }, {})
  });

  const [errorMessage, setErrorMessage] = useState('');

  const toggleFilterVisibility = (filterName) => {
    setVisibleFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
  };

  const handleTempFilterChange = (name, value) => {
    const updatedTempFilters = { ...tempFilters, [name]: value };
    setTempFilters(updatedTempFilters);
  }

  const handleFilterChange = (name, value) => {
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const applyFilters = () => {
    if (maxPrice < minPrice) {
      setErrorMessage('Cena maksymalna musi być większa od ceny minimalnej');
      return;
    }

    if (maxPower < minPower) {
      setErrorMessage('Moc maksymalna musi być większa od mocy minimalnej');
      return;
    }

    const minPriceValue = minPrice === '' ? 0 : minPrice;
    const maxPriceValue = maxPrice === '' ? initialMaxPrice : maxPrice;
    const minPowerValue = minPower === '' ? 0 : minPower;
    const maxPowerValue = maxPower === '' ? 1000 : maxPower;

    const updatedFilters = {
      ...tempFilters,
      price: [minPriceValue, maxPriceValue],
      power: [minPowerValue, maxPowerValue]
    };

    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
    setErrorMessage('');
  };

  const showMoreManufacturers = () => {
    setVisibleManufacturers((prev) => prev + 10);
  }

  const clearFilters = () => {
    setFilters({});
    setMinPrice(0);
    setMaxPrice(initialMaxPrice);
    setMinPower('');
    setMaxPower('');
    onFilterChange({});
    setErrorMessage('');
  }

  return (
    <section className='border-2 rounded-lg p-2 ml-4 sticky top-20'>
      <div className="filter-container p-4 w-80">
        {Object.keys(filters).length > 0 && (
          <div className='flex justify-center mb-4 border-b-2'>
            <button
              onClick={clearFilters}
              className='pb-4 text-gray-400 text-sm hover:underline block mx-auto'
            >
              Wyczyść filtry
            </button>
          </div>
        )}
        <div className='border-b-2'>
          <div
            className='flex justify-between items-center mb-3 cursor-pointer hover:bg-gray-200 rounded p-1.5'
            onClick={() => toggleFilterVisibility('price')}
          >
            <h4 className="font-semibold text-blue-600">Cena</h4>
            <FontAwesomeIcon
              icon={visibleFilters.price ? faChevronUp : faChevronDown}
            />
          </div>
          {visibleFilters.price && (
            <div className='mb-5'>
              <MultiRangeSlider
                minPrice={minPrice}
                maxPrice={maxPrice}
                setMinPrice={setMinPrice}
                setMaxPrice={setMaxPrice}
                productType={productType}
              />
              {errorMessage && errorMessage.includes('Cena') && (
                <div className='text-red-500 text-xs mt-2 text-center'>
                  {errorMessage}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="my-4 border-b-2">
          <div
            className="flex justify-between items-center mb-3 cursor-pointer hover:bg-gray-200 rounded p-1.5"
            onClick={() => toggleFilterVisibility('power')}
          >
            <h4 className="font-semibold text-blue-600">Moc</h4>
            <FontAwesomeIcon
              icon={visibleFilters.power ? faChevronUp : faChevronDown}
            />
          </div>
          {visibleFilters.power && (
            <div className='mb-5'>
              <div className="flex justify-between items-center pt-5 mx-8">
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    placeholder="od"
                    value={minPower}
                    onChange={(e) => setMinPower(e.target.value)}
                    className="px-3 py-2 border border-gray-200 rounded w-24 text-center pr-8"
                  />
                  <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-xs">
                    {productType === 'panel' ? 'Wp' : 'kW'}
                  </span>
                </div>
                -
                <div className="relative">
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    placeholder="do"
                    value={maxPower}
                    onChange={(e) => setMaxPower(e.target.value)}
                    className="px-3 py-2 border border-gray-200 rounded w-24 text-center pr-8"
                  />
                  <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-xs">
                    {productType === 'panel' ? 'Wp' : 'kW'}
                  </span>
                </div>
              </div>
              {errorMessage && errorMessage.includes('Moc') && (
                <div className='text-red-500 text-xs mt-2 text-center'>
                  {errorMessage}
                </div>
              )}
            </div>
          )}
        </div>
        {filtersSchema.map((filter, index) => {
          switch (filter.type) {
            case 'checkbox':
              return (
                <div key={filter.name} className={`my-4 border-b-2 ${index === filtersSchema.length - 1 ? 'mb-4' : ''}`}>
                  <div
                    className="flex justify-between items-center mb-3 cursor-pointer hover:bg-gray-200 rounded p-1.5"
                    onClick={() => toggleFilterVisibility(filter.name)}
                  >
                    <h4 className="font-semibold text-blue-600">{filter.title}</h4>
                    <FontAwesomeIcon
                      icon={visibleFilters[filter.name] ? faChevronUp : faChevronDown}
                    />
                  </div>
                  {visibleFilters[filter.name] && (
                    <div className='p-2'>
                      {Object.entries(filter.values).slice(0, visibleManufacturers).map(([key, label]) => (
                        <label key={key} className="block mb-2">
                          <input
                            type="checkbox"
                            name={filter.name}
                            value={key}
                            checked={tempFilters[filter.name]?.includes(key) || false}
                            onChange={(e) => {
                              const newValues = tempFilters[filter.name] || [];
                              if (e.target.checked) {
                                newValues.push(key);
                              } else {
                                const index = newValues.indexOf(key);
                                if (index > -1) {
                                  newValues.splice(index, 1);
                                }
                              }
                              handleTempFilterChange(filter.name, newValues);
                            }}
                            className='mr-1'
                          />
                          {label}
                        </label>
                      ))}
                      {Object.entries(filter.values).length > visibleManufacturers && (
                        <button
                          onClick={showMoreManufacturers}
                          className='mt-2 px-4 py-1 text-gray-400 text-sm hover:underline block mx-auto'
                        >
                          Wyświetl więcej...
                        </button>
                      )}
                    </div>
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
        <div className='flex justify-center mt-5'>
          <button
            onClick={applyFilters}
            className='bg-blue-600 hover:bg-blue-500 text-white font-semibold rounded w-full mx-4 px-4 py-2 hover:scale-110 hover:shadow-md
         transition-transform ease-in-out duration-150'
          >
            FILTRUJ
          </button>
        </div>
      </div>
    </section>
  );
};

export default Filters;
