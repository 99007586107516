import React from 'react';
import { Link } from 'react-router-dom';
import PurchaseButton from './PurchaseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const ProductList = ({ products = [], productType, loading }) => {

  if (loading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <l-dot-pulse size="80" speed="1.0" color="#9ca3af"></l-dot-pulse>
      </div>
    );
  }

  if (!Array.isArray(products) || products.length === 0) {
    return (
      <div className='flex flex-col justify-center items-center h-full'>
        <FontAwesomeIcon icon={faBoxOpen} size="5x" className="text-gray-300 mb-4" />
        <p className='text-3xl font-semibold text-gray-300'>Brak wyników</p>
      </div>
    );
  }

  return (
    <ul className='mx-auto'>
      {products.map((product) => (
        <li key={product.id} className="p-2 border-2 border-gray-200 flex m-auto mb-8 rounded-lg shadow-lg cursor-pointer">
          <Link to={`/${productType}/${product.id}`} className="flex-grow flex" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="flex flex-grow">
              <img
                src={product.img_url}
                alt={product.name}
                className="w-40 h-48 object-cover mx-5 rounded-xl my-6 drop-shadow-lg"
              />
              <div className="mr-4 flex-grow">
                <h3 className="font-bold text-2xl ml-6 max-w-3xl">{product.name}</h3>
                <div className="grid grid-cols-5 text-center text-lg text-blue-600 mt-6">
                  <span>Moc</span>
                  <span>Producent</span>
                  <span>Dostępność</span>
                  {productType === 'panel' ? <span>Cena za Wp</span> : <span>Fazy</span>}
                  <span>Rodzaj</span>
                </div>
                <div className="grid grid-cols-5 text-center mt-3">
                  <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">
                    {productType === 'panel' ? `${product.power} Wp` : `${product.power} kW`}
                  </p>
                  <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">{product.manufacturer}</p>
                  <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">{product.availability}</p>
                  {productType === 'panel' ? (
                    <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">
                      {(product.price_netto / product.power).toFixed(2)} zł/Wp
                    </p>
                  ) : (
                    <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">{product.phase}</p>
                  )}
                  <p className="border-2 rounded-lg m-auto px-2 py-1 text-base">{product.type}</p>
                </div>
                <div className="flex justify-evenly text-gray-400 ml-6 mt-12">
                  <p>Sprzedawca: <span className='font-semibold ml-2'>{product.seller}</span></p>
                  <p>Wysyłka: <span className='font-semibold ml-2'>{product.shipping}</span></p>
                </div>
              </div>
            </div>
          </Link>
          <div className="flex flex-col items-center justify-center mx-6 mt-4" onClick={(e) => e.stopPropagation()}>
            <p className="font-bold text-blue-600 text-3xl whitespace-nowrap">{product.price} zł</p>
            <p className="text-sm text-gray-500">{product.price_netto} zł bez VAT</p>
            <PurchaseButton link={product.link} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ProductList;