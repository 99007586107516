import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PurchaseButton from '../components/PurchaseButton';
import ToggleMoreInfoButton from '../components/ToggleMoreInfoButton';
import { getPanelManufacturerLogoPath } from '../utils';
import { fetchProducts } from '../requests';

const PanelDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [showMoreInfo, setShowMoreInfo] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const fetchedData = await fetchProducts(`get_panel/${id}`);
      setProduct(fetchedData);
      setLoading(false);
    };

    const fetchSimilarProducts = async () => {
      const fetchedSimilarData = await fetchProducts(`similar_products_panels/${id}`);
      setSimilarProducts(fetchedSimilarData);
    };

    fetchProduct();
    fetchSimilarProducts();
  }, [id]);

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  if (loading) {
    return (
      <div className='fixed inset-0 flex justify-center items-center'>
        <l-dot-pulse size="80" speed="1.0" color="#9ca3af"></l-dot-pulse>
      </div>
    )
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="mx-40 my-28">
      <div className='flex'>
        <div className='relative flex flex-col items-center'>
          <img
            src={getPanelManufacturerLogoPath(product.manufacturer)}
            alt={`${product.manufacturer} logo`}
            className='object-contain absolute -top-20 scale-50 border-4 drop-shadow-lg rounded-3xl'
          />
          <img
            src={product.img_url}
            alt={product.name}
            className='w-[400px] h-[400px] object-fit rounded-3xl border drop-shadow-lg mt-3'
          />
        </div>
        <div className="w-full ml-8 whitespace-nowrap">
          <h1 className="font-semibold text-3xl border-b-2 pb-5 whitespace-normal">{product.name}</h1>
          <div className="flex mt-8 border-gray-200 pb-5">
            <div className="grid grid-cols-2 grid-rows-3 text-lg mx-20">
              <div className='flex gap-x-3'>
                <span className='text-blue-600'>Producent</span>
                <p className='font-light'>{product.manufacturer}</p>
              </div>
              <div className="col-start-1 row-start-2 flex gap-x-3">
                <span className='text-blue-600'>Moc</span>
                <p className='font-light'>{product.power} W</p>
              </div>
              <div className="col-start-1 row-start-3 flex gap-x-3">
                <span className='text-blue-600'>Sprzedawca</span>
                <p className='font-light'>{product.seller}</p>
              </div>
              <div className="col-start-2 row-start-1 flex gap-x-3">
                <span className='text-blue-600'>Dostępność</span>
                <p className='font-light'>{product.availability} szt.</p>
              </div>
              <div className="col-start-2 row-start-2 flex gap-x-3">
                <span className='text-blue-600'>Cena za Wp</span>
                <p className='font-light'>{product.price_wp} zł/Wp <span className='text-sm text-gray-400'>(bez VAT)</span></p>
              </div>
              <div className="flex gap-x-3">
                <span className='text-blue-600'>Wysyłka</span>
                <p className="font-light">{product.shipping}</p>
              </div>
            </div>
            <div className="ml-24 relative text-center">
              <p className="text-md text-blue-600">od <span className="font-bold text-4xl text-blue-600">{product.price} zł</span></p>
              <p className="text-sm text-gray-500 mb-8">{product.price_netto} zł bez VAT</p>
              <PurchaseButton link={product.link} />
              <p className="text-xs text-gray-400">Wysyłka od</p>
            </div>
          </div>
          <div className="text-center border-t-2">
            <ToggleMoreInfoButton onClick={toggleMoreInfo} isExpanded={showMoreInfo} />
            {showMoreInfo && (
              <div className="grid grid-cols-3 mt-2 pt-3">
                <div className="flex flex-col items-center text-center px-20">
                  <div className="flex justify-between w-full">
                    <span className='text-blue-600'>Długość</span>
                    <p className='font-light'>{product.module_length} mm</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600'>Szerokość</span>
                    <p className='font-light'>{product.module_width} mm</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600'>Grubość</span>
                    <p className='font-light'>{product.module_thickness} mm</p>
                  </div>
                </div>
                <div className="flex flex-col items-center text-center border-l-2 border-r-2 border-gray-200 px-12">
                  <div className="flex justify-between w-full">
                    <span className='text-blue-600'>Gwarancja produktowa</span>
                    <p className='font-light'>{product.product_warranty} lat</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600 whitespace-nowrap'>Gwarancja na wydajność</span>
                    <p className='font-light whitespace-nowrap'>{product.eff_warranty} lat</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600'>Obramowanie</span>
                    <p className='font-light'>{product.frame_colour}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center text-center px-20">
                  <div className="flex justify-between w-full">
                    <span className='text-blue-600'>Waga</span>
                    <p className='font-light'>{product.weight} kg</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600'>Sprawność</span>
                    <p className='font-light'>{product.efficiency} %</p>
                  </div>
                  <div className="flex justify-between w-full mt-3">
                    <span className='text-blue-600'>Rodzaj</span>
                    <p className='font-light'>{product.type}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mt-12 pt-4 mx-44'>
        <h2 className='text-center text-lg mb-4 text-blue-600'> Inne oferty tego produktu:</h2>
        <div>
          <ul>
            {similarProducts.map((similarProduct, index) => (
              <li key={index} className="p-4 border-2 border-gray-200 flex m-auto mb-6 rounded-xl shadow-xl">
                <Link to={`/panel/${similarProduct.id}`}>
                  <img
                    src={similarProduct.img_url}
                    alt={similarProduct.name}
                    className='w-32 h-40 object-fit mx-4 rounded-lg drop-shadow-lg my-4'
                  />
                </Link>
                <div className='mr-4'>
                  <Link to={`/panel/${similarProduct.id}`}>
                    <h3 className="font-bold text-xl ml-4">{similarProduct.name}</h3>
                  </Link>
                  <div className='grid grid-cols-5 gap-2 text-center text-lg text-blue-600 mt-4'>
                    <span>Moc</span>
                    <span>Producent</span>
                    <span>Dostępność</span>
                    <span>Cena za Wp</span>
                  </div>
                  <div className='grid grid-cols-5 gap-2 text-center'>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base'>{similarProduct.power} Wp</p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base'>{similarProduct.manufacturer}</p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base whitespace-nowrap'>{similarProduct.availability} szt.</p>
                    <div className='col-span-1 relative m-auto'>
                      <p className='border rounded-lg px-2 py-1 text-base'>{product.price_wp} zł/Wp</p>
                      <p className='absolute text-xs text-gray-400' style={{ top: '100%', left: '50%', transform: 'translateX(-50%)' }}>bez VAT</p>
                    </div>
                    <div className='col-span-1'>
                      <p className='font-bold text-blue-600 text-4xl whitespace-nowrap'>{similarProduct.price} zł</p>
                      <p className='text-gray-500 text-sm'>{similarProduct.price_netto} zł bez VAT</p>
                    </div>
                    <p className='col-span-1 text-gray-400 mt-4'>Seller: {similarProduct.seller}</p>
                    <p className='col-span-3 text-gray-400 mt-4'>Wysyłka: {similarProduct.shipping}</p>
                    <PurchaseButton link={similarProduct.link} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

  );
};

export default PanelDetails;
