import React from 'react'

const PurchaseButton = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
    <div className='flex justify-center'>
      <button
        className='flex justify-center items-center border-black rounded-md px-6 py-2 mt-2 text-white font-semibold bg-blue-600 hover:bg-blue-500 hover:scale-110 hover:shadow-md
         transition-transform ease-in-out duration-150 whitespace-nowrap'>
        <img src='/trolley.png' alt="Cart" className='w-6 h-6 inline-block mr-2' />
        DO SKLEPU
      </button>
    </div>
    </a>
  )
}

export default PurchaseButton