import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='mx-auto w-1/2'>
      <div className='mt-20 grid grid-cols-3 grid-rows-2 gap-4 h-96'>
        <Link to="/panele" className='row-span-2 rounded-xl overflow-hidden cursor-pointer'>
          <div className='h-full w-full relative'>
            <img src='panele.png' alt='Panele' className='h-full w-full hover:scale-110 transition duration-300 ease-in-out' />
            <div className='opacity-100 absolute bottom-0 left-0 flex justify-end items-end text-4xl text-white p-6'>
              Moduły PV
            </div>
          </div>
        </Link>
        <Link to="/inwertery" className='col-span-2 rounded-xl overflow-hidden cursor-pointer'>
          <div className='h-full w-full relative'>
            <img src='falowniki-poprawione.png' alt='Inwertery' className='h-full w-full hover:scale-110 transition duration-300 ease-in-out' />
            <div className='opacity-100 absolute -bottom-3 left-4 flex justify-end items-end text-4xl text-white p-6'>
              Falowniki
            </div>
          </div>
        </Link>
        <div className='col-start-2 rounded-xl overflow-hidden'>
        {/* <div className='h-full w-full hover:scale-110 transition duration-300 ease-in-out relative'> */}
          <div className='h-full w-full relative'>
            <img src='pompa-czarnobiala.png' alt='Pompy' className='h-full w-full blur-sm' />
            <div className='opacity-100 absolute -top-4 -right-3  flex justify-end items-end```` text-white p-6'>
              Wkrótce...
            </div>
            <div className='opacity-100 absolute -bottom-2 -left-1  flex justify-end items-end text-4xl text-white p-6'>
              Pompy Ciepła
            </div>
          </div>
        </div>
        <div className='rounded-xl overflow-hidden'>
        {/* <div className='h-full w-full hover:scale-110 transition duration-300 ease-in-out relative'> */}
          <div className='h-full w-full relative'>
            <img src='magazyn-czarnobialy.png' alt='Magazyny' className='h-full w-full blur-sm' />
            <div className='opacity-100 absolute -top-2 -right-1 flex justify-end items-end text-white p-4'>
              Wkrótce...
            </div>
            <div className='opacity-100 absolute bottom-0 left-1 flex justify-end items-end text-4xl text-white p-4'>
              Magazyny
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero