import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Filters from '../components/Filters';
import ProductList from '../components/ProductList';
import Sort from '../components/Sort';
import { useProducts } from '../hooks/useProducts';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { panelFiltersSchema, inverterFiltersSchema } from '../filtersSchema';

const ProductPage = ({ productType }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialPageIndex = parseInt(new URLSearchParams(location.search).get('page')) || 1;
  const [loading, setLoading] = useState(true);

  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const pageSize = 25;
  const [filters, setFilters] = useState({});
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', pageIndex);
    navigate({ search: queryParams.toString() });
  }, [pageIndex, navigate, location.search]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [pageIndex, filters, sortOrder]);

  const handleFilterChange = (newfilters) => {
    setFilters(newfilters);
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
  };

  const handlePageChange = (e, value) => {
    setPageIndex(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const { products, totalPages } = useProducts(productType, pageIndex, pageSize, filters, sortOrder);
  const filtersSchema = productType === 'panel' ? panelFiltersSchema : inverterFiltersSchema;

  return (
    <>
      <div className='flex justify-end mr-72 mt-10'>
        <Sort onSortChange={handleSortChange} />
      </div>
      <div className='flex mt-10'>
        <Filters filtersSchema={filtersSchema} onFilterChange={handleFilterChange} productType={productType} />
        <div className='flex-1 px-24'>
          <ProductList productType={productType} products={products} loading={loading} />
        </div>
      </div>
      <Stack spacing={2} className='justify-center mt-10 flex items-center'>
        <Pagination
          count={totalPages}
          page={pageIndex}
          onChange={handlePageChange}
          color='primary'
          size='large'
          showFirstButton showLastButton
        />
      </Stack>
    </>
  );
};

export default ProductPage;
