const apiUrl = process.env.REACT_APP_API_URL;

export const fetchProducts = async (url) => {
  try {
    //console.log('Fetching products from:', `${apiUrl}${url}`);
    const response = await fetch(`${apiUrl}${url}`);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    return await response.json();

  } catch (error) {
    console.error('Error fetching products!', error);
    throw error;
  }
};